.container{
    width: 100%;
    justify-content: flex-start;
    // background-color: #333;
}
.headerNewConatiner{
    width: 100%;
    height: 50px;
}
.NewConatiner{
    display: flex;
    flex-direction: row;
    height: auto;
    justify-content: space-between;
    flex-wrap: wrap;    
    // background-color: #999;
    
    p {
        overflow: hidden; // Removendo barra de rolagem
        text-overflow: ellipsis; // Adicionando "..." ao final
        display: -webkit-box;
        -webkit-line-clamp: 2; // Quantidade de linhas
        -webkit-box-orient: vertical; 
    }
}